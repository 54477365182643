<template>
<div style="height:100%"> 
  <formularioArea/>
</div>
 
</template>

<script>
import FormularioArea from '../components/Area/FormularioArea.vue'

export default {
  components: { FormularioArea },

}
</script>

<style>

</style>