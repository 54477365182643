<template>
  <v-card
    color="grey lighten-4"
    flat
    height="110px"
    tile
   
  >
    <v-toolbar
      extended
      extension-height="50"
       style=" background-color: #111226;"
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

   
<img src="https://i.ibb.co/C6z4MJf/logo-xs-nb.png" to="/"  style="   
    width: 25%;
    margin-top: 80px;
    margin-right: 30px;">
      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
        <v-btn icon>
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>



</style>
