<template>
<div style="height:100%">
<formularioProyectos/>
</div>
</template>

<script>
import FormularioProyectos from '../components/Proyectos/FormularioProyectos.vue'
export default {
  components: { FormularioProyectos },

}
</script>

<style>

</style>