<template>
<div style="height:100%"> 
    <formularioCapa/> 
</div>
 
</template>

<script>
import FormularioCapa from '../components/Capa/FormularioCapa'
export default {
  components: { FormularioCapa },

}
</script>

<style>

</style>